
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { App } from '../api/src/models/App';
import { Resource } from '../api/src/models/Resource';
import effectsMixin from '../mixins/effects';
import uiMixin from '../mixins/ui';
import EffectCustomListItem from '~/components/effect-custom-list-item';
import ResourceValueButtonFull from '~/components/resource-value-button-full';

const resourceName = Resource.ResourceName.enum.Aether;

export default {
  name: 'EffectsList',
  components: {
    EffectCustomListItem,
    ResourceValueButtonFull,
  },
  mixins: [
    effectsMixin,
    uiMixin,
  ],
  data() {
    return {
      activeTabIndex: 0,
      resourceName,
      effectsRandom: [],
    };
  },
  computed: {
    itemsLocal() {
      return this.effects.map(i => ({
        ...i,
        style: {
          color: i.color ? i.color : 'var(--sl-color-neutral-700)',
        },
      }));
    },
    itemsCustomLocal() {
      return this.effectsCustom;
    },
    itemsCustomDiscarded() {
      return this.$store.getters['effects/itemsCustomDiscarded'];
    },
    effectsRandomLocal() {
      return this.effectsRandom.map(e => ({
        ...e,
        isPurchased: !!this.effectsCustom.concat(this.itemsCustomDiscarded).find(i => i.hash === e.hash),
        isDisabled: !!this.effectsCustom.find(i => i.hash === e.hash) || e.cost > this.countResource,
      }));
    },
    countResource() {
      return this.$store.getters['resources/countAether'];
    },
    hasAetherCreation() {
      return !!this.$store.getters['systems/getValueForUpgradeByName'](this.$store.state.systems.upgradeName.AetherCreation);
    },
  },
  mounted() {
    this.setCustomRandom();
    this.$store.dispatch('userSettings/edit', {
      effectsViewedAt: new Date(),
    });
  },
  methods: {
    async setCustomRandom(isReroll = false) {
      const isSetting = !isReroll || await this.uiMsgBoxConfirm('Are you sure you want to change your effects options?');

      if (isSetting) {
        this.effectsRandom = [];
        const seeds = [
          '', // a universal effect for the day
          this.$store.state.user.uid, // an effect for this user for today
        ];

        seeds.forEach((s) => {
          const seedAdd = isReroll ? Math.random() : '';
          const e = this.$store.getters['effects/getCustomRandom'](`${s}${seedAdd}`);
          this.effectsRandom.push(e);
        });

        if (isReroll) {
          await this.$store.dispatch('userSettings/editResources', [{
            count: this.countResource - 1,
            name: this.resourceName,
          }]);
          this.uiNotify('Effects options changed!', { conceptName: App.Concept.ConceptName.enum.Effect });
          this.uiNotify('1', {
            type: 'ResourceUse',
            payload: {
              resourceName: this.resourceName,
            },
            severity: 'secondary',
          });
          Haptics.vibrate();
        }
      }
    },
    async handleAdd(effect) {
      if (effect && this.effectsRandom.find(e => e.hash === effect.hash)) {
        Haptics.impact({ style: ImpactStyle.Medium });
        const setting = Resource.Setting[this.resourceName];
        const isPurchasing = await this.uiMsgBoxConfirm(`Are you sure you want to forge the "${effect.label}" for ${effect.cost} ${setting.label.toLowerCase()}?`);

        if (isPurchasing) {
          const result = await this.$store.dispatch('userSettings/addEffectCustom', { hash: effect.hash });

          if (result) {
            await this.$store.dispatch('userSettings/editResources', [{
              count: this.countResource - effect.cost,
              name: this.resourceName,
            }]);
            this.uiNotify('Effect forged!', { conceptName: App.Concept.ConceptName.enum.Effect });
            this.uiNotify(`${effect.cost}`, {
              type: 'ResourceUse',
              payload: {
                resourceName: this.resourceName,
              },
              severity: 'secondary',
            });
            Haptics.vibrate();
          }
        }
      }
    },
  },
};
